<template>
	<ConfirmDialog></ConfirmDialog>
	
	<div class="p-grid" style="height: 100%">
		<div class="p-col-12 p-md-12" style="height: 100%">
			<div class="card p-fluid" style="height: 100%">
                <CrmDataTable baseEntityName="bm_yedekparcateklifsiparis" :options="CrmDataTable_options" :isHeaderVisible="true" :isNewButtonVisible="true" @onRowSelect="onRowSelect" />
            </div>
        </div>
    </div>
</template>

<script>
import user from '../store/user';

export default {
	data() {
		return {
			CrmDataTable_options: {
				searchAttributes: ["bm_teklifbasligi", "bm_teklifno", "bm_siparisno", "bm_musterigercekidname", "bm_urunidname", "bm_uniteidname", "bm_musterireferansno" ],
				forceSavedQueryId: 'F5B8E2EB-AA75-E211-9AD9-E61F135C9177',
				//conditionalFormatting: []
			},
		}
	},
	created() {
		debugger;
		if (this.profileData) {
			const yetkisiVarmi = user.checkPermissionModul(this.profileData, 'Y.P. Teklif / Sipariş');
			if (yetkisiVarmi == false) {
				this.$router.replace({ name: 'accessdenied' });
			}
		}
	},
	methods: {
		onRowSelect(data) {
			debugger;
			//this.$router.push({ name: 'bm_yedekparcateklifsiparis', params: { id: data.entityId } });

			let features = 'directories=no,menubar=no,status=no,titlebar=no,toolbar=no';

			let routeData = this.$router.resolve({ name: 'bm_yedekparcateklifsiparis', params: { id: data.entityId } });
			window.open(routeData.href, '_blank', features);
		}
	},
	computed: {
		profileData(){
			return this.$store.getters.getProfile;
		}
	}
}
</script>

<style lang="scss" scoped>

</style>
